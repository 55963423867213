<template>
    <v-container fluid no-gutters>
        <v-select v-model="selectedHeaders" :items="hideActions? headers : headers.concat(actions)" label="Επιλέξτε στήλες" multiple outlined return-object/>
        <span id='element-to-convert' style="font-family: Arial, Helvetica, sans-serif; font-weight: bold; font-size: 1.3rem !important;">
            <v-data-table
                item-key="id"
                :headers="selectedHeaders"
                :items="items"
                :items-per-page="size"
                :loading="isLoading"
                hide-default-footer
                disable-sort
            >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td
                            v-for="header in selectedHeaders"
                            :key="header.value"
                        >
                            <span v-if="header.text === 'categories'"> {{ getCategories(props.item) }}</span>
                            <span v-else-if="header.value === 'amount'">
                                {{ (props.item[header.value] - ((props.item['withholding'] / 100) * props.item[header.value] / (1 + props.item['fpa'] / 100))).toLocaleString("el-GR") + '€' }}
                            </span>
                            <span v-else-if="header.type === 'date' || header.type === 'createdAtDate'"> {{ header.subLevel && props.item[header.value] ? getDate(props.item[header.value][header.subLevel]) : getDate(props.item[header.value]) }}</span>
                            <span v-else-if="header.value === 'status'"> {{ props.item[header.value] === 'PENDING' ? 'ΕΚΚΡΕΜΜΕΙ' : props.item[header.value] === 'PAID' ? 'ΠΛΗΡΩΜΕΝΟ' : 'ΑΚΥΡΩΜΕΝΟ' }}</span>
                            <span v-else-if="header.value === 'paymentMethod'"> {{ props.item[header.value] === 'CASH' ? 'ΜΕΤΡΗΤΑ' : props.item[header.value] === 'CHECK' ? 'ΕΠΙΤΑΓΗ' : props.item[header.value] === 'BANK' ? 'ΤΡΑΠΕΖΑ' : 'ΚΟΥΠΟΝΙΑ' }}</span>
                            <span v-else>{{ header.subLevel && props.item[header.value] ? props.item[header.value][header.subLevel] : props.item[header.value] }}</span>
                            <div v-if="!hideActions && header.value==='Edit'" class="buttons">
                                <v-btn icon class="mx-2" dark small color="black" @click="handleClick('edit', props.item)">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                            <delete-button v-if="!hideActions && header.value==='Delete'" :type="false" @delete-entry="onDelete(props.item, localPage)" />
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-card v-if="totals" class="py-7" outlined>
                <v-row>
                    <v-col cols="4" align="center">
                        Μεικτά: {{ totals.total.toLocaleString("el-GR") + '€' }}
                    </v-col>
                    <v-col cols="4" align="center">
                        Καθαρά: {{ totals.totalAfterTaxes.toLocaleString("el-GR") + '€' }}
                    </v-col>
                    <v-col cols="4" align="center">
                        Τελικό: {{ totals.finalTotal.toLocaleString("el-GR") + '€' }}
                    </v-col>
                </v-row>
            </v-card>
        </span>
        <custom-dialog :dialog="showDialog" @update="showDialog = !showDialog" />
        <v-row v-if="totalPages" class="pagination">
            <v-col cols="10">
                <v-pagination
                    v-model="localPage"
                    :length="totalPages"
                    total-visible="7"
                    color="black"
                    circle
                    @input="paginationChange"
                />
            </v-col>
            <v-col cols="1">
                <v-select
                    v-model="localSize"
                    :hint="`Αντικείμενα / σελίδα: ${localSize}`"
                    :items="sizes"
                    persistent-hint
                    return-object
                    single-line
                    @input="sizeChange"
                />
            </v-col>
            <v-col cols="1">
                <v-autocomplete
                    v-model="localPage"
                    :hint="`Μεταφορά στη σελίδα: ${localPage}`"
                    :items="Array(totalPages).fill().map((v,i)=> i + 1)"
                    persistent-hint
                    return-object
                    single-line
                    @input="paginationChange"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CustomDialog from '@/components/Dialog'
    import momentTimezone from 'moment-timezone'
    import DeleteButton from '@/components/DeleteButton'

    export default {
        components: { CustomDialog, DeleteButton },
        props: {
            items: {
                type: Array,
                default: null
            },
            totals: {
                type: Object,
                default: null
            },
            headers: {
                type: Array,
                default: null
            },
            totalPages: {
                type: Number,
                default: null
            },
            page: {
                type: Number,
                default: null
            },
            size: {
                type: Number,
                default: null
            },
            type: {
                type: String,
                default: null
            },
            hideActions: {
                type: Boolean,
                default: false
            }
        },
        data: () => {
            return {
                selectedHeaders: [],
                localPage: 1,
                localSize: 10,
                sizes: [5, 10, 30, 60, 100],
                message: 'Please verify your ',
                role: '',
                showDialog: false,
                images: [
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/852099ce-b105-48e4-b97a-5b6fd14d7ddf/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/20784a41-1187-4979-af37-64eea1843fd6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/fa3de888-3106-4f49-a1a0-53f3e6864bf6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/e46d4b68-5974-4781-b5f9-f317c50f87b6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/852099ce-b105-48e4-b97a-5b6fd14d7ddf/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' }
                ],
                actions: [
                    {
                        text: 'Επεξεργασία',
                        value: 'Edit'
                    },
                    {
                        text: 'Διαγραφή',
                        value: 'Delete'
                    }
                ],
                isLoading: true
            }
        },
        watch: {
            items() {
                if (Array.isArray(this.items)) {
                    this.isLoading = false
                }
            }
            // headers() {
            //     this.selectedHeaders = this.hideAtcions ? this.headers : this.headers.concat(this.actions)
            // }
        },
        created() {
            this.localPage = this.page
            this.localSize = this.size
            this.selectedHeaders = this.hideAtcions ? this.headers : this.headers.concat(this.actions)
        },
        methods: {
            onDelete(item, page, parent) {
                this.$emit('onDelete', item, page, parent)
            },
            handleClick(action, item) {
                this.$emit('handleClick', action, item)
            },
            paginationChange() {
                this.$emit('paginationChange', this.localPage, this.localSize)
            },
            sizeChange() {
                this.localPage = 1
                this.paginationChange()
            },
            getCategories(item) {
                let categories = []
                item.categories.forEach(element => { categories.push(element.alias) })
                return categories.join(', ')
            },
            getDate(date) {
                return momentTimezone(date).tz('Europe/Athens').format('DD/MM/YYYY').toString()
            }
        }
    }
</script>

<style scoped>
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        font-size: 1.1rem !important;
    }

    .v-data-table::v-deep th {
        font-size: 1.1rem !important;
    }

    .pagination {
        justify-content: center;
    }

    .product-photos {
        display: inline-block;
        vertical-align:middle;
    }

    .close-icon {
        background: red;
        padding: 0.1rem;
        border-radius: 50%;
        text-align: center;
        line-height: auto;
        vertical-align: middle;
    }

    .check-icon {
        background: green;
        padding: 0.1rem;
        border-radius: 50%;
        text-align: center;
        line-height: auto;
        vertical-align: middle;
    }

</style>
