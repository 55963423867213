<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Announcement
            </v-card-title>

            <v-card-text>
                {{ message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="closeDialog"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        props: {
            message: {
                type: String,
                value: '',
                default: null
            },
            dialog: {
                type: Boolean,
                value: false,
                default: null
            }
        },
        data() {
            return {
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false
                this.$emit('update')
            }
        }
    }
</script>
